import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-blog-starter/src/templates/single-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p" {...{
        "className": "firstword"
      }}>{`Harf Nida`}</strong>{` Or Vocative Particle in Arabic helps us to gain the attention of a person by calling/addressing him. This concept is heavily used in Quran in multiple places, viz`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`يَا آدَمُ اسْكُنْ أَنتَ وَزَوْجُكَ الْجَنَّةَ`}</code>{` (Ref 2:35)`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`يَا بَنِي إِسْرَائِيلَ اذْكُرُوا نِعْمَتِيَ الَّتِي أَنْعَمْتُ عَلَيْكُمْ`}</code>{` (Ref 2:40)`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`يَا أَيُّهَا النَّاسُ اعْبُدُوا رَبَّكُمُ الَّذِي خَلَقَكُمْ وَالَّذِينَ مِن قَبْلِكُمْ لَعَلَّكُمْ تَتَّقُونَ`}</code>{` (Ref 2:21)`}</li>
    </ul>
    <br />
    <p>{`So without further ado, lets begin the discussion with the definition of harf النِّداء itself. It is a مصدر (masdar) from word  `}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`نادى  ينادي  ناد  النِّداء`}</code><br parentName="p"></br>{`
`}{`meaning to call, appeal etc`}</p>
    <h2>{`Types of Harf Nida`}</h2>
    <p>{`There are 5 types of Harf nida `}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p" {...{
            "className": "heading1"
          }}>{`Harf يا`}</strong></p>
        <ul parentName="li">
          <li parentName="ul">{`This letter is called Ummul Bab (ام الباب)`}</li>
          <li parentName="ul">{`We use this letter to call someone `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`Near us OR Far away`}</strong>{` from us (ننادي بها القريب والبعيد)`}</li>
          <li parentName="ul">{`Example: `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`يا زيدُ`}</code></li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p" {...{
            "className": "heading1"
          }}>{`Harf الهمزة`}</strong></p>
        <ul parentName="li">
          <li parentName="ul">{`We use this letter to call someone `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`Near us`}</strong>{` (ننادي بها القريب)`}</li>
          <li parentName="ul">{`Example: `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`أزيدُ`}</code></li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p" {...{
            "className": "heading1"
          }}>{`Harf أيْ`}</strong></p>
        <ul parentName="li">
          <li parentName="ul">{`We use this letter to call someone `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`in between i.e. neither far nor close`}</strong>{` (ننادي بها المتوسط)`}</li>
          <li parentName="ul">{`Example: `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`أيْ زيدُ`}</code></li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p" {...{
            "className": "heading1"
          }}>{`Harf أيا`}</strong></p>
        <ul parentName="li">
          <li parentName="ul">{`We use this letter to call someone `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`Far away`}</strong>{` from us (ننادي بها البعيد)`}</li>
          <li parentName="ul">{`Example: `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`أيا زيدُ`}</code></li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p" {...{
            "className": "heading1"
          }}>{`Harf هيا`}</strong></p>
        <ul parentName="li">
          <li parentName="ul">{`We use this letter to call someone `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`Far away`}</strong>{` from us (ننادي بها البعيد)`}</li>
          <li parentName="ul">{`Example: `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`هيا زيدُ`}</code></li>
        </ul>
      </li>
    </ol>
    <p><strong parentName="p">{`Note:`}</strong>{` There is Khilaf regarding above rules but above are general guidelines.`}<br parentName="p"></br>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "561px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "50.920245398773%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAAsTAAALEwEAmpwYAAABV0lEQVQoz41STU/CQBDt//8RHg3Bk9GYiCSGIiUQIYIIgiAX+WhLS79wd2d2tk7xQGI5OJnDm5f3MrMva+WlMiZfeqo9WnXe1nZv4bx+fXrAZLmsc2azcHXltlW5ebq8blxUa+8r8V8zVyook3mUUBjTAYrxrMwq7yVNGjVIRYgoAYQiYIpOy03JzAyZnDWoDSIdzze6ADmPTGoqBCcrmzGJKYkxSZQATDMIQhXsDZEBgDCU/s6A4lEFjANMU1QIUWSSWMexlbVbfuPx0H8mrcV0MrqqruoPufjW7nZbr7n1mt5uSIi03VrV7uV0wrLDS395d5t12hbsdtJzcR/yGTpNsvVa+X5uyEipPA9830iRE2EQKM/VSVzIov1hu4Gd/zcwjgX4BCE5Is2YCBD5BQzoTNoc4rF/c/iYzXq9nm3b4/F4OBw2m83BYNDtdh3Hmc/np2907B/FEzrwloOkTwAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Harf Nida",
          "title": "Harf Nida",
          "src": "/static/e568ec832173faf6a1ae58f34f287b63/410f3/harf-nida.png",
          "srcSet": ["/static/e568ec832173faf6a1ae58f34f287b63/222b7/harf-nida.png 163w", "/static/e568ec832173faf6a1ae58f34f287b63/ff46a/harf-nida.png 325w", "/static/e568ec832173faf6a1ae58f34f287b63/410f3/harf-nida.png 561w"],
          "sizes": "(max-width: 561px) 100vw, 561px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <p><strong parentName="p">{`Point to Consider`}</strong>{`  `}</p>
    <ul>
      <li parentName="ul">{`When we say, `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`يا زيد`}</code>{` it is similar in saying, `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`أنادي زيداً`}</code></li>
      <li parentName="ul">{`When we say someone is near (القريب) then he may be`}
        <ul parentName="li">
          <li parentName="ul">{`Near us physically`}</li>
          <li parentName="ul">{`Near us metaphorically (i.e. we are talking about someone far away from us but we feel that He is with us)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Similarly for البعيد (far away), someone may be close to us but we feel He is not with us OR We feel he is very high in rank/position.`}</li>
    </ul>
    <h2>{`Cases of المنادى in Harf Nida`}</h2>
    <p><strong parentName="p" {...{
        "className": "arabic"
      }}>{`حالات النداء`}</strong></p>
    <p>{`There are 5 situations of المنادى when used with Harf Nida,`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p" {...{
            "className": "heading1"
          }}>{`الحالة الأولى`}</strong></p>
        <ul parentName="li">
          <li parentName="ul">{`المنادى `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`علمٌ مفرد`}</strong></li>
          <li parentName="ul">{`علمٌ means name of person, country, city etc and مفردٌ means not مضاف and شبيه بالمضاف.`}</li>
          <li parentName="ul">{`This type has damma on the منادى and is estimated in Nasb form. `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`يبنى على الضم في محل نصب`}</code></li>
          <li parentName="ul">{`Example: يا `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`زيدُ`}</strong>{`. Here زيدُ is مفردٌ`}<br parentName="li"></br>
            {``}<strong parentName="li" {...{
              "className": "heading2"
            }}>{`Irab of يا زيدُ`}</strong>
            <blockquote parentName="li">
              <p parentName="blockquote"><strong parentName="p" {...{
                  "className": "arabic irab"
                }}>{`زيد`}</strong>{`
منادى مبني على الضم في محل نصب`}</p>
            </blockquote>
          </li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p" {...{
            "className": "heading1"
          }}>{`الحالة الثانية`}</strong></p>
        <ul parentName="li">
          <li parentName="ul">{`المنادى `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`نكرة مقصودة`}</strong></li>
          <li parentName="ul">{`By نكرة مقصودة we mean even though the person addressed is نكرة (Indefinite) but still we are talking to a specific person. `}</li>
          <li parentName="ul">{`Example: يا `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`رجلُ ساعدني`}</strong>{` (O Man, Help me). Here we are not calling any man but to a specific man that we are seeing now for help`}</li>
          <li parentName="ul">{`It is made Raf as per situation e.g. Damma, Alif (for dual) etc and is estimated in Nasb form. `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`يبنى على ما يرفع به في محل نصب`}</code>
            <ul parentName="li">
              <li parentName="ul">{`Example: يا رجل`}<strong parentName="li">{`ا`}</strong>{`ن, here its Raf on Alif `}<code parentName="li" {...{
                  "className": "language-text"
                }}>{`منادى مبني على الألف`}</code></li>
              <li parentName="ul">{`Example: يا مسلم`}<strong parentName="li">{`و`}</strong>{`ن, here its Raf on Waw `}<code parentName="li" {...{
                  "className": "language-text"
                }}>{`منادى مبني على الواو`}</code></li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p" {...{
            "className": "heading1"
          }}>{`الحالة الثالثة`}</strong></p>
        <ul parentName="li">
          <li parentName="ul">{`المنادى `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`نكرة غير مقصودة`}</strong></li>
          <li parentName="ul">{`By نكرة غير مقصودة, we mean we are talking in general`}</li>
          <li parentName="ul">{`Example: يا `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`عاصياً`}</strong>{` تبْ إلى الله (O Sinner, Repent to Allah)`}</li>
          <li parentName="ul">{`This type has منادى in Nasb form i.e. يُنصَب`}<br parentName="li"></br>
            <strong parentName="li" {...{
              "className": "heading2"
            }}>{`Irab of يا عاصياً تُبْ إلى الله`}</strong>
            <blockquote parentName="li">
              <p parentName="blockquote"><strong parentName="p" {...{
                  "className": "arabic irab"
                }}>{`عاصياً`}</strong>{`
منادى `}<strong parentName="p" {...{
                  "className": "irabhighlight"
                }}>{`منصوب`}</strong>{` وعلامة نصبه الفتحة`}</p>
            </blockquote>
          </li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p" {...{
            "className": "heading1"
          }}>{`الحالة الرابعة`}</strong></p>
        <ul parentName="li">
          <li parentName="ul">{`المنادى `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`مضاف`}</strong></li>
          <li parentName="ul">{`This type has منادى in Nasb form i.e. يُنصَب`}</li>
          <li parentName="ul">{`Example: يا `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`طالبَ العلمِ`}</strong>{` اجتهد`}<br parentName="li"></br>
            <strong parentName="li" {...{
              "className": "heading2"
            }}>{`Irab of يا طالبَ العلمِ اجتهد`}</strong>{` `}
            <blockquote parentName="li">
              <p parentName="blockquote"><strong parentName="p" {...{
                  "className": "arabic irab"
                }}>{`طالب`}</strong>{`
منادى `}<strong parentName="p" {...{
                  "className": "irabhighlight"
                }}>{`منصوب`}</strong>{` وعلامة نصبه الفتحة وهو مضاف`}</p>
            </blockquote>
          </li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p" {...{
            "className": "heading1"
          }}>{`الحالة الخامسة`}</strong></p>
        <ul parentName="li">
          <li parentName="ul">{`المنادى `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`شبيه بالمضاف`}</strong></li>
          <li parentName="ul">{`This type has منادى in Nasb form i.e. يُنصَب`}</li>
          <li parentName="ul">{`Example: يا `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`طالباً للعلم`}</strong>{` اجتهد`}
            <ul parentName="li">
              <li parentName="ul">{`The above example is technically not مضاف but the meaning of `}<code parentName="li" {...{
                  "className": "language-text"
                }}>{`طالباً للعلم`}</code>{` is similar to `}<code parentName="li" {...{
                  "className": "language-text"
                }}>{`طالبَ العلمِ`}</code>{`. Hence, we call it `}<code parentName="li" {...{
                  "className": "language-text"
                }}>{`شبيه بالمضاف`}</code>{` Or Similar to مُضاف`}<br parentName="li"></br>
                <strong parentName="li" {...{
                  "className": "heading2"
                }}>{`Irab of يا طالباً للعلم اجتهد`}</strong>
                <blockquote parentName="li">
                  <p parentName="blockquote"><strong parentName="p" {...{
                      "className": "arabic irab"
                    }}>{`طالباً`}</strong>{`
منادى `}<strong parentName="p" {...{
                      "className": "irabhighlight"
                    }}>{`منصوب`}</strong>{` وعلامة نصبه الفتحة`}</p>
                </blockquote>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ol>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "30.67484662576687%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA2UlEQVQY021QyW7DIBTk/7+n10o5J+qhIsYE8AaqYwM2dRIT1wuUJEpbyR3N4S0z86QH/Aqz8zusXjbvr1u2ecu2WF1H/y+Ad+7GB+71sriqsSlXuWj5hznKyzT7tSwQ/MQ8N35xIcD9vTBNy8N4t/yuwIlRE6O+qgfd6GivItQlbLa2Y1Qh1GLcUTL3fZekGsKrlEPTKAg/CQlz0JKDivZWyi9jZAR1jKzgfhzPWWooOSXMFnlorRAhelAqyIJe4zj4wfoNuRCUsUrrsq4zzpOiOBAiynKt/AYiJlPmcennCQAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Cases for Munada",
          "title": "Cases for Munada",
          "src": "/static/56157d14b8457d65193465be3038c59a/a6d36/cases-for-munada.png",
          "srcSet": ["/static/56157d14b8457d65193465be3038c59a/222b7/cases-for-munada.png 163w", "/static/56157d14b8457d65193465be3038c59a/ff46a/cases-for-munada.png 325w", "/static/56157d14b8457d65193465be3038c59a/a6d36/cases-for-munada.png 650w", "/static/56157d14b8457d65193465be3038c59a/0f67e/cases-for-munada.png 921w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h2>{`Rules of Harf Nida with ال`}</h2>
    <p>{`Arabic being a fantastic language and Arabs being wonderful, experimental people felt that we need to decorate the rules of Harf Nida. So they came up with a strategy with the use of Harf Nida with ال, which goes as follows:`}</p>
    <ul>
      <li parentName="ul">{`We can't use harf Nida with a word starting with ال. Basically we can't say, `}<s>{`يا الرجل`}</s>{`. But they have an `}<strong parentName="li" {...{
          "className": "exception"
        }}>{`Exception`}</strong>{` to this rule with لفظ جلاله `}<strong parentName="li">{`الله`}</strong>{`, i.e we can say, يا اللهُ (See how amazing). But usually they make Nida to Allah by saying, `}<strong parentName="li">{`اللهُمَّ`}</strong>{`  `}
        <blockquote parentName="li">
          <p parentName="blockquote">{`(الكلمة التي تبدأ ب ( ال ) لا يستخدم العرب معها حرف النداء مباشرة ويستخدمون ( أيها  ) و ( أيتها`}<br parentName="p"></br>{`
`}{`(وهناك استثناء ل (لفظ الجلالة `}</p>
        </blockquote>
      </li>
    </ul>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Irab of اللهُمَّ`}</strong></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`الله`}</strong>{`
لفظ الجلالة منادى مبني على الضم في محل نصب`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`الميم`}</strong>{`
عوض عن حرف النداء المحذوف وهو حرف مبني على الفتح لا محل له`}</p>
    </blockquote>
    <br />
    <ul>
      <li parentName="ul">{`To make Nida for word starting with ال e.g. الرجل, المرأة`}
        <ul parentName="li">
          <li parentName="ul">{`For male, we insert `}<strong parentName="li">{`أيها`}</strong>{` in the middle e.g. يا `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`أيها`}</strong>{` الرجل. `}</li>
          <li parentName="ul">{`For female, we insert the word `}<strong parentName="li">{`أيتها`}</strong>{` in the middle e.g. يا `}<strong parentName="li" {...{
              "className": "bg-yellow"
            }}>{`أيتها`}</strong>{` المرأة`}</li>
        </ul>
      </li>
    </ul>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Irab of يا أيها الرجل`}</strong></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`يا`}</strong>{`
حرف نداء مبني لا محل له`}</p>
    </blockquote>
    <br />
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`أيُ`}</strong>{`
`}<strong parentName="p" {...{
          "className": "irabhighlight"
        }}>{`منادى`}</strong>{` مبني على الضم في محل نصب`}</p>
    </blockquote>
    <br />
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`ها`}</strong>{`
حرف تنبيه مبني لا محل له`}</p>
    </blockquote>
    <br />
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`الرجل`}</strong>{`
بدل مرفوع بالضمة`}</p>
    </blockquote>
    <br />
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Irab of يا أيها الذين آمنوا`}</strong></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`يا`}</strong>{`
حرف نداء مبني لا محل له`}</p>
    </blockquote>
    <br />
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`أيُ`}</strong>{`
`}<strong parentName="p" {...{
          "className": "irabhighlight"
        }}>{`منادى`}</strong>{` مبني على الضم في محل نصب`}</p>
    </blockquote>
    <br />
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`ها`}</strong>{`
حرف تنبيه مبني لا محل له`}</p>
    </blockquote>
    <br />
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`الذين`}</strong>{`
اسم موصول مبني في محل رفع بدل`}</p>
    </blockquote>
    <h2>{`Rules of Harf Nida when its Mudhaf with ي`}</h2>
    <p><strong parentName="p" {...{
        "className": "arabic"
      }}>{`إذا كان المنادى مضافاً لياء المتكلم`}</strong></p>
    <br />
    <p>{`Arabs being intelligent race further decorated the rules of Harf Nida when its Mudhaf with ي. So they said, Even though we have a perfect sentence like,`}<br parentName="p"></br>{`
`}{`يا `}<strong parentName="p" {...{
        "className": "bg-yellow"
      }}>{`صديقيْ`}</strong>{` أقبل (O My friend Come here !!)  `}</p>
    <br />
    <p>{`We can have the same sentence in multiple forms which are listed below,`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`يا `}<strong parentName="p" {...{
            "className": "bg-yellow"
          }}>{`صديقيْ`}</strong>{` أقبل`}</p>
        <ul parentName="li">
          <li parentName="ul">{`ياء المتكلم ساكنة`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`يا `}<strong parentName="p" {...{
            "className": "bg-yellow"
          }}>{`صديقيَ`}</strong>{` أقبل`}</p>
        <ul parentName="li">
          <li parentName="ul">{`ياء المتكلم مفتوحة`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`يا `}<strong parentName="p" {...{
            "className": "bg-yellow"
          }}>{`صديقيَهْ`}</strong>{` أقبل`}</p>
        <ul parentName="li">
          <li parentName="ul">{`ياء المتكلم مفتوحة مع هاء السكت`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`يا `}<strong parentName="p" {...{
            "className": "bg-yellow"
          }}>{`صديقَا`}</strong>{` أقبل`}</p>
        <ul parentName="li">
          <li parentName="ul">{`ياء المتكلم قُلِبَتْ ألفاً`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`يا `}<strong parentName="p" {...{
            "className": "bg-yellow"
          }}>{`صديقِ`}</strong></p>
        <ul parentName="li">
          <li parentName="ul">{`حذفت ياء المتكلم وبقيت الكسرة`}
            <blockquote parentName="li">
              <p parentName="blockquote"><strong parentName="p" {...{
                  "className": "arabic irab"
                }}>{`الياء المحذوفة`}</strong>{`
ضمير مبني في محل جر مضاف إليه`}</p>
            </blockquote>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`يا `}<strong parentName="p" {...{
            "className": "bg-yellow"
          }}>{`صديقُ`}</strong></p>
        <ul parentName="li">
          <li parentName="ul">{`حذفت ياء المتكلم وضمَّ ما قبله `}</li>
        </ul>
      </li>
    </ul>
    <h2>{`Rules of Harf Nida with أب Or أم`}</h2>
    <p>{`Arabs being wonderful people allowed to use ت in place of ياء المتكلم in case of أب Or أم i.e. we can say `}<strong parentName="p">{`يا أبتِ`}</strong>{` OR `}<strong parentName="p">{`يا أبتَ`}</strong>{` in place if `}<strong parentName="p">{`يا أبي`}</strong></p>
    <blockquote>
      <p parentName="blockquote">{`مع كلمة ( أب ) و ( أم ) يجوز أيضاً استخدام التاء`}</p>
    </blockquote>
    <h2>{`Dropping Harf Nida`}</h2>
    <p>{`It is permissible to drop Harf Nida when Nida is made`}</p>
    <ul>
      <li parentName="ul">{`Example: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`ربِّ استجب دعائي`}</code>{` is equal to `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`يا ربِّ استجب دعائي`}</code></li>
      <li parentName="ul">{`Quranic Example: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`يوسفُ أعرض عن هذا`}</code>{` (Ref: 12:29), is equal to saying `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`يا يوسفُ أعرض عن هذا`}</code></li>
      <li parentName="ul"><strong parentName="li" {...{
          "className": "exception"
        }}>{`Exception`}</strong>{` The above rule has an exception with اسم إشارة i.e. we can't say `}<s>{`هذا أقبل`}</s>{` in place of `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`يا هذا أقبل`}</code>{`.`}<br parentName="li"></br>
        <strong parentName="li">{`Pls Note`}</strong>{`: Some Ulema says, It is permissible to say `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`هذا أقبل`}</code>{` (Khilaf). So I rest my case there people and its upto you which opinion you follow. :)`}
        <blockquote parentName="li">
          <p parentName="blockquote">{`إلا إذا كان المنادى اسم إشارة فلا يجوز حذف حرف النداء.`}</p>
        </blockquote>
      </li>
    </ul>
    <br />
    <p>{`Before concluding I would like to cover one more topic which involves use of Harf Nida and that is ...`}</p>
    <h2>{`Tarkheem`}</h2>
    <p><strong parentName="p" {...{
        "className": "arabic"
      }}>{`الترخيم`}</strong></p>
    <p>{`Tarkheem comes from the word  `}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`رخَّم  يرخِّم الترخيم`}</code><br parentName="p"></br>{`
`}{`means to make your voice soft or sweet.`}</p>
    <br />
    <p>{`In nahw, it means to drop the last letter or last two letters.  `}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`حذف آخر (حرف أو حرفين) من الاسم في النداء`}</code></p>
    <br />
    <p>{`Tarkheem usually occurs in case of Nida but sometimes we use this in other place as well.`}<br parentName="p"></br>{`
`}{`For example: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`يا عائشَةُ`}</code>{` with Tarkheem becomes `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`يا عائشُ`}</code>{` (we have dropped ة i.e last letter)`}</p>
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Irab of يا عائشُ`}</strong></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`عائشُ`}</strong>{`
منادى مبني على الضم على التاء المحذوفة للترخيم في محل نصب`}</p>
    </blockquote>
    <br />
    <p><strong parentName="p">{`But the question arises why we do so?`}</strong><br parentName="p"></br>{`
`}<em parentName="p">{`Short Answer`}</em>{`, When we like/love someone we use this as a nickname.`}</p>
    <br />
    <h3>{`Observation`}</h3>
    <p>{`One thing to notice is when we dropped ة from عائشَةُ we changed the haraka above ش from from  َ  (fatha) to   ُ (damma).  `}</p>
    <br />
    <p>{`We can also make tarkheem from عائشَةُ without changing the haraka over ش.`}<br parentName="p"></br>{`
`}{`Example: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`يا عائشَةُ`}</code>{` with Tarkheem becomes `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`يا عائشَ`}</code>{`  `}</p>
    <p><strong parentName="p" {...{
        "className": "heading1"
      }}>{`Irab of يا عائشَ`}</strong></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p" {...{
          "className": "arabic irab"
        }}>{`عائشَ`}</strong>{`
منادى مبني على الفتح على التاء المحذوفة للترخيم في محل نصب`}</p>
    </blockquote>
    <br />
    <p>{`In case of يا عائشَ, since the harakah has not changed we (as listner) will wait for the speaker to complete the word i.e. we are waiting for the ة to come. This expression is called `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`لغة من ينتظر`}</code>{` i.e. language which is waiting. `}</p>
    <br />
    <p>{`But in case of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`يا عائشُ`}</code>{` we have changed the harakah over ش indicating we have stopped. So this expression is called `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`لغة من لا ينتظر`}</code>{` i.e. language which is not waiting.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "401px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "55.21472392638037%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAABYklEQVQoz41RaU/CQBDt//+mfiBewZsPxiAexAStpVp6g0LEI5gGY0WxBKHHbne7W7cgEhCNk81k9s1783ayXDwZhMbXllttgmozqD2Buo0qVtDqItai8XRwEzeaEPJibW4xs7JznFrPptb20ruF+6Yzav4hjuOI0DsbVhrdmuWybD44V5Zrd+A/nAcRYgIR9XzsesgHEUCU/NT9Jk42x9FQQSMSITzjxTPFlFLErCMyHkTGyNSQCTFrYUzoLB+GszPtTBmGQooRhqHr9EmY1AycGJpwEAah1+kTGLJ6QMOcq8rQ1IGhsQwNnWVfU3xTJxB8K/0hoWy4iuypMiobga76uspBVX4R+IOF+Xpu//Ekr2a2S1ubYcXEnve1M0JA16TNjfP06k0u28gf3R7mCstL9lmBCzTFuRTbotAS+Gf+tKeUerIEykYEwOjro74qvwr8myh8yFJbLL5fFLuy5GnKJxBwaJNGu/cOAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Types of Tarkheem",
          "title": "Types of Tarkheem",
          "src": "/static/df7737bdb196e8a216d50d75f213cc81/9144d/tarkheem.png",
          "srcSet": ["/static/df7737bdb196e8a216d50d75f213cc81/222b7/tarkheem.png 163w", "/static/df7737bdb196e8a216d50d75f213cc81/ff46a/tarkheem.png 325w", "/static/df7737bdb196e8a216d50d75f213cc81/9144d/tarkheem.png 401w"],
          "sizes": "(max-width: 401px) 100vw, 401px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h2>{`Rules of Tarkheem`}</h2>
    <ul>
      <li parentName="ul">{`We only do this with Marifa (معرفة) i.e. Alam`}</li>
      <li parentName="ul">{`We only do this with individual/single noun (مُفْرَد) i.e. it should not be إضافة.`}</li>
      <li parentName="ul">{`We do this with majorly with first case of Nida and sometimes with second case.`}
        <ul parentName="li">
          <li parentName="ul">{`Ulema who supports Tarkheem in second case gives example of `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`صاحِب`}</code>{` in which it is permissible to say `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`يا صاحِ`}</code>{`. But other ulema says, it is irregular or exceptional special case and we should not do this for any other Nida in second case. So it's upto you what opinion you chose. ;)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`We can only drop either one letter or Two letters.`}
        <ul parentName="li">
          <li parentName="ul">{`When single letter is dropped`}
            <ul parentName="li">
              <li parentName="ul"><code parentName="li" {...{
                  "className": "language-text"
                }}>{`يا طلحَةُ`}</code>{` will be  `}<code parentName="li" {...{
                  "className": "language-text"
                }}>{`يا طلحَ`}</code>{` Or `}<code parentName="li" {...{
                  "className": "language-text"
                }}>{`يا طلحُ`}</code></li>
              <li parentName="ul"><code parentName="li" {...{
                  "className": "language-text"
                }}>{`يا عامِرُ`}</code>{` will be  `}<code parentName="li" {...{
                  "className": "language-text"
                }}>{`يا عامِ`}</code>{` Or `}<code parentName="li" {...{
                  "className": "language-text"
                }}>{`يا عامُ`}</code></li>
              <li parentName="ul"><code parentName="li" {...{
                  "className": "language-text"
                }}>{`يا سعادُ`}</code>{` will be  `}<code parentName="li" {...{
                  "className": "language-text"
                }}>{`يا سعا`}</code></li>
            </ul>
          </li>
          <li parentName="ul">{`When two letters are dropped.`}
            <ul parentName="li">
              <li parentName="ul"><strong parentName="li">{`Rule`}</strong>{`: If there is a five or six lettered word drop two letters.`}</li>
              <li parentName="ul"><code parentName="li" {...{
                  "className": "language-text"
                }}>{`يا مرْوَانُ`}</code>{` will be  `}<code parentName="li" {...{
                  "className": "language-text"
                }}>{`يا مروَ`}</code></li>
              <li parentName="ul"><code parentName="li" {...{
                  "className": "language-text"
                }}>{`يا منصُورُ`}</code>{` will be  `}<code parentName="li" {...{
                  "className": "language-text"
                }}>{`يا منصُ`}</code></li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">{`Words for which Tarkheem is not allowed are as follows,`}
        <ul parentName="li">
          <li parentName="ul">{`Words having 3 letters e.g. هند, نوح, زيد and عمرو (here عمرو is 3 letters as و is extra)`}
            <ul parentName="li">
              <li parentName="ul"><strong parentName="li" {...{
                  "className": "exception"
                }}>{`Exception`}</strong>{` Words having 3 letters + ة at the end can have takheem e.g. `}<code parentName="li" {...{
                  "className": "language-text"
                }}>{`يا هبة`}</code>{` will be  `}<code parentName="li" {...{
                  "className": "language-text"
                }}>{`يا هبَ`}</code></li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <br />
    <p>{`And this is how the article on Harf Nida finally concludes ...`}</p>
    <h2>{`Harf Nida Reference`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.qutoofacademy.com/",
          "rel": "nofollow noopener"
        }}>{`Qutoof Academy`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      